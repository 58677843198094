import { Component, OnInit } from '@angular/core';
import { OrderService } from '../order.service';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';
import { SelectionModel } from '@angular/cdk/collections';
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { OrderFilter } from '../order.model';

@Component({
  selector: 'app-by-user',
  templateUrl: './by-user.component.html',
  styleUrls: ['./by-user.component.scss']
})
export class ByUserComponent extends BaseIndex {

	model = 'order';
	displayedColumns: string[] = ['select', '_id', 'fullname', 'mobile', 'orders_count', 'orders_total', 'tools'];
	theDetailsComponent = null;
	theFormComponent = null;
	searchForm: Boolean = false;
	filter: OrderFilter = new OrderFilter();

	constructor(protected theService: OrderService,
		protected router: Router, protected dialog: MatDialog) {
		super(theService, router, dialog);
  }
  
  ngOnInit(): void {
    this.get();
  }

	get() {
		this.isLoading = true;
		this.theService.getByUser(this.filter, '', this.pagination.pageIndex, this.pagination.pageSize).subscribe((response) => {
			this.items = response.results.data;
			this.dataSource.data = response.results.data;
			this.pagination.length = response.results.total;
			this.selection = new SelectionModel<ITable>(true, []);
			this.SelectionIds = [];
			this.isLoading = false;
		});
	}

	afterCloseDetails() {
		this.get();
	}

	setSearchForm() {
		this.searchForm = !this.searchForm;
	}

}
