import { Component } from '@angular/core';
import { Slide, SlideError } from '../slide.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'slide';
	item: Slide = new Slide();
	errors: SlideError = new SlideError();
	validate: SlideError = new SlideError();

	getEmptyErrors() {
		return new SlideError();
	}

}
