import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './pages/login/login.component';

import { NotFoundComponent } from './pages/not-found/not-found.component';

import { AuthorizationGuardService } from './@core/services/authorization.guard.service';

import { HomeComponent } from './pages/home/home.component';
import { CategoryComponent } from './pages/category/category.component';
import { RankComponent } from './pages/rank/rank.component';
import { FeatureComponent } from './pages/feature/feature.component';
import { EmployeeComponent } from './pages/employee/employee.component';
import { ProductComponent } from './pages/product/product.component';
import { OrderComponent } from './pages/order/order.component';
import { SettingComponent } from './pages/setting/setting.component';
import { BillComponent } from './pages/order/bill/bill.component';
import { ByUserComponent } from './pages/order/by-user/by-user.component';
import { ByProductComponent } from './pages/order/by-product/by-product.component';
import { IssueComponent } from './pages/order/issue/issue.component';
import { IssuesComponent } from './pages/order/issues/issues.component';
import { SlideComponent } from './pages/slide/slide.component';
//import { InventoryComponent } from './pages/inventory/inventory.component';

const routes: Routes = [
	{ path: '', component: HomeComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'category', component: CategoryComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'rank', component: RankComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'feature', component: FeatureComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'employee', component: EmployeeComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'slide', component: SlideComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'order', component: OrderComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'order/by_product', component: ByProductComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'order/by_user', component: ByUserComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'issue', component: IssuesComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'order/:id', component: BillComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'order/:id/issues', component: IssueComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'product', component: ProductComponent, canActivate: [AuthorizationGuardService] },
	//{ path: 'inventory', component: InventoryComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'setting', component: SettingComponent, canActivate: [AuthorizationGuardService] },
	{ path: 'login', component: LoginComponent },
	{ path: '404', component: NotFoundComponent },
	{ path: '**', redirectTo: '/404' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
