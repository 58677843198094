import { Component, Inject } from '@angular/core';
import { BaseDetails } from 'src/app/@core/libraries/pages/base-details';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguagesService } from 'src/app/@core/data/languages.service';
import { i18n } from 'src/app/@core/libraries/i18n';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { MessageComponent } from 'src/app/@theme/message/message.component';

@Component({
	selector: 'app-details',
	templateUrl: './details.component.html',
	styleUrls: ['./details.component.scss']
})
export class DetailsComponent extends BaseDetails {

	item: any;
	isLoading: boolean;
	config = {
		lang: localStorage.getItem('lang') || 'en',
	};
	supplier_ids: Array<String> = [];
	payment_details_keys: Array<String> = [];
	order_status_list: Array<Object> = [];
	public readonly environment: Object = environment;

	constructor(
		protected languagesService: LanguagesService,
		public http: HttpClient,
		public router: Router,
		public dialogRef: MatDialogRef<BaseDetails>,
		public _snackBar: MatSnackBar,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		super(languagesService, http, dialogRef, data);
	}

	ngOnInit(): void {
		this.isLoading = true;
		this.http.get(`${environment.apiUrl}/order/${this.id}`).subscribe((response: any) => {
			this.item = response.results;
			this.afterInit();
			this.isLoading = false;
		});
		this.order_status_list = Object.keys(i18n.order_status_list).map((k) => {
			return {
				key: k,
				value: i18n.order_status_list[k],
			};
		});
	}

	afterInit() {
		this.supplier_ids = this.item && this.item['products'] ? Object.keys(this.item['products']) : [];
		this.payment_details_keys = Object.keys(this.item['payment_details']);
	}

	updateQuantity(_id, quantity) {
		this.isLoading = true;
		this.http.put(`${environment.apiUrl}/order/${this.id}/product/${_id}`, {
			quantity: quantity
		}).subscribe((response: any) => {
			this.isLoading = false;
			this.ngOnInit();
		});
	}

	updateStatus() {
		this.isLoading = true;
		this.http.put(`${environment.apiUrl}/order/${this.id}/status`, {
			status: this.item.status
		}).subscribe((response: any) => {
			this.showMessage(response['results']['message']);
			this.isLoading = false;
		});
	}

	deleteProduct(_id) {
		this.isLoading = true;
		this.http.delete(`${environment.apiUrl}/order/${this.id}/product/${_id}`).subscribe((response: any) => {
			this.isLoading = false;
			this.ngOnInit();
		});
	}

	openBill() {
		this.close();
		this.router.navigate(['order/' + this.id]);
	}

	createIssue() {
		this.close();
		let user = localStorage.getItem('auth.user');
		try {
			user = user = user ? JSON.parse(user) : {};
		} catch (error) {
			this.router.navigate(['login']);
		}
		const issue = {
			order_id: this.id,
			solved: false,
			employee_name: user['fullname'],
			issue_type: 'Open New Issue',
			details: 'Open New Issue',
		};
		this.http.post(`${environment.apiUrl}/order/issue`, issue).subscribe((response: any) => {
			this.showMessage(response['results']['message']);
			this.router.navigate(['order/' + this.id + '/issues']);
		});
	}

	openIssues() {
		this.close();
		this.router.navigate(['order/' + this.id + '/issues']);
	}

	showMessage(message) {
		this._snackBar.openFromComponent(MessageComponent, {
			duration: 5 * 1000,
			data: message
		});
	}

	decrease_quantity(id) {
		const elm = document.getElementById(`q_${id}`);
		if (parseInt(elm['value']) == 0) {
			return;
		}
		elm['value'] = parseInt(elm['value']) - 1;
		const event = new Event('change');
		elm.dispatchEvent(event);
	}

	increase_quantity(id) {
		const elm = document.getElementById(`q_${id}`);
		elm['value'] = parseInt(elm['value']) + 1;
		const event = new Event('change');
		elm.dispatchEvent(event);
	}


	getRoundedPrice(price) {
		return (Math.ceil(parseFloat(price) * 200) / 200).toFixed(3);
	}

}
