import { LanguagesObject } from '../../@core/data/languages-object';
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';

export class Category implements ITable {

	_id: String = '';
	name: LanguagesObject = new LanguagesObject();
	parent_id: String = '';
	picture: String = '';
	featured: Number = 0;
	status: Boolean = false;
	category_n_billObj: Object = {};
	category_n_billArr: Array<Object> = [];
	category_n_inventoryObj: Object = {};
	category_n_inventoryArr: Array<Object> = [];

}

export class CategoryError implements IError {

	name: LanguagesObject = new LanguagesObject();
	parent_id: String = '';
	picture: String = '';
	featured: String = '';
	status: String = '';

}
