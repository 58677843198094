import { Component } from '@angular/core';
import { Category, CategoryError } from '../category.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { Inventory } from '../../inventory/inventory.model';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'category';
	item: Category = new Category();
	errors: CategoryError = new CategoryError();
	validate: CategoryError = new CategoryError();
	parents: Category[] = [];
	inventories: Inventory[] = [];
	suppliers: any;
	inventoriesObj: any[] = [];
	categoryninventory: Object = {
		'inventory_id': '',
		'sorting': 0,
		'status': false
	};
	validate_categoryninventory: Object = {};
	categorynbill: Object = {
		'inventory_id': '',
		'sorting': 0
	};
	validate_categorynbill: Object = {};

	initialize() {
		this.loadCategories();
		this.loadStores();
	}

	loadCategories() {
		this.http.get(`${environment.apiUrl}/category`, {
			params: new HttpParams()
				.set('filter', 'status=true&parent_id=null')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.parents = response['results']['data'];
			});
	}

	loadStores() {
		this.http.get(`${environment.apiUrl}/inventory`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.inventories = response['results']['data'];
				for (const i of this.inventories) {
					this.inventoriesObj[i._id.toString()] = i.name[this.config.lang];
				}
			});
	}

	add_categorynbill() {
		if (this.item.category_n_billArr === undefined) {
			this.item.category_n_billArr = [];
		}
		this.item.category_n_billArr.push(Object.assign({}, this.categorynbill));
		this.categorynbill = {};
	}

	add_categoryninventory() {
		if (this.item.category_n_inventoryArr === undefined) {
			this.item.category_n_inventoryArr = [];
		}
		this.item.category_n_inventoryArr.push(Object.assign({}, this.categoryninventory));
		this.categoryninventory = {};
	}

	getBeforeSave() {
		const data = this.item;

		// bill
		const category_in_bill = data.category_n_billArr;
		if (data.category_n_billObj === undefined) {
			data.category_n_billObj = {};
		}
		if (category_in_bill) {
			for (const i of category_in_bill) {
				data.category_n_billObj[i['inventory_id']] = {
					'sorting': i['sorting']
				};
			}
		}
		// bill

		// inventory
		const category_in_inventory = data.category_n_inventoryArr;
		if (data.category_n_inventoryObj === undefined) {
			data.category_n_inventoryObj = {};
		}
		if (category_in_inventory) {
			for (const i of category_in_inventory) {
				data.category_n_inventoryObj[i['inventory_id']] = {
					'sorting': i['sorting'],
					'status': i['status'],
				};
			}
		}
		// inventory
		return data;
	}

	getEmptyErrors() {
		return new CategoryError();
	}

	updateCnB(inventory_id) {
		this.item.category_n_billArr = this.deleteFromObj(this.item.category_n_billArr, 'inventory_id', inventory_id);
	}

	updateCnS(inventory_id) {
		this.item.category_n_inventoryArr = this.deleteFromObj(this.item.category_n_inventoryArr, 'inventory_id', inventory_id);
	}

}
