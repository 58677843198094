<h1 mat-dialog-title>{{ data.action | i18n }}</h1>
<div mat-dialog-content>
	<mat-card>
		<mat-card-subtitle>{{ 'details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="col-12">

				<mat-form-field appearance="outline" class="form-group col-md-12">
					<mat-label>{{ 'name' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.name" [errorStateMatcher]='validate.name'
						placeholder="{{ 'name' | i18n }}" />
					<mat-error>{{ errors.name }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-12">
					<mat-label>{{ 'email' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.email" [errorStateMatcher]='validate.email'
						placeholder="{{ 'email' | i18n }}" />
					<mat-error>{{ errors.email }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'department' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'department' | i18n }}" [(ngModel)]="item.department"
						[errorStateMatcher]='validate.department' name="department">
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let c of departments" [value]="c._id">
							{{c.name}}
						</mat-option>
					</mat-select>
					<mat-error>{{ errors.department }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'inventory' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'inventory' | i18n }}" [(ngModel)]="item.inventory_id"
						[errorStateMatcher]='validate.inventory_id' name="inventory_id">
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let c of inventories" [value]="c._id">
							{{c.name[config.lang]}}
						</mat-option>
					</mat-select>
					<mat-error>{{ errors.inventory_id }}</mat-error>
				</mat-form-field>


				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'status' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'status' | i18n }}" [(ngModel)]='item.status'
						[errorStateMatcher]='validate.status' name="status">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.status }}</mat-error>
				</mat-form-field>
			</div>
		</mat-card-content>
	</mat-card>

</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
