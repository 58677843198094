import { Component } from '@angular/core';
import { Product, ProductError, Store } from '../product.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { environment } from 'src/environments/environment';
import { HttpParams, HttpResponse, HttpEventType } from '@angular/common/http';
import { Category } from '../../category/category.model';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { OptionType } from '../../option/option.model';
import { cloneArray, cloneObject } from '../../../@core/libraries/healper';
import { Feature } from '../../feature/feature.model';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'product';
	item: Product = new Product();
	errors: ProductError = new ProductError();
	validate: ProductError = new ProductError();
	categories: Category[] = [];
	stores: Store[] = [];
	categoriesObj: any[] = [];
	productncategory: Object = {
		'category_id': '',
		'rank_id': '',
		'name': {},
		'sorting': 0
	};
	validate_productncategory: Object = {};
	storesObj: any[] = [];
	productnstore: Object = {
		'store_id': '',
		'quantity': 0,
		'status': false
	};
	validate_productnstore: Object = {};
	public Editor = ClassicEditor;
	brands: any;
	features: any;
	ranks: any;
	ranksObj: any[] = [];
	ranksByCategory: any[] = [];
	attribute_groups: any[] = [];
	attributes: any[] = [];
	variants: any[] = [];
	variant_product: any = {};
	validate_variant_product: any = {};
	options: any[] = [];
	option_type: typeof OptionType = OptionType;
	variant_product_options: Array<any> = [];
	variant_n_store: Object = {
		'store_id': '',
		'quantity': 0,
		'status': false
	};
	validate_variant_n_store: Object = {};
	allow_managing_quantities: boolean = environment.allow_managing_quantities || true;
	minDate: Date = new Date();
	valid_until_time: { hours: Number, minutes: Number } = { hours: 0, minutes: 0 };

	initialize() {
		this.loadBrands();
		this.loadCategories();
		this.loadFeatures();
		this.loadRanks();
		this.loadStores();
		this.loadOptions();
	}

	afterInit() {
		if (this.item.discount_price_valid_until) {
			console.log('date: ', this.item.discount_price_valid_until);
			this.valid_until_time.hours = new Date(this.item.discount_price_valid_until).getHours();
			this.valid_until_time.minutes = new Date(this.item.discount_price_valid_until).getMinutes();
			console.log('yes: ', 'hours: ', new Date(this.item.discount_price_valid_until).getHours());
		} else {
			console.log('no: ', this.item.discount_price_valid_until);
		}
	}

	loadAttributeGroups() {
		this.http.get(`${environment.apiUrl}/attribute_group`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.attribute_groups = response['results']['data'];
			});
	}

	loadOptions() {
		this.http.get(`${environment.apiUrl}/option`, {
			params: new HttpParams()
				.set('filter', `status=true`)
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.options = response['results']['data'].map((o) => {
					o.options_list = o.options_list.map((i) => {
						delete o._id;
						delete o.created;
						delete o.status;
						delete o.lastUser_update;
						delete o.created;
						delete o.modified;
						i.type = o.type;
						i.option = o;
						return i;
					});
					return o;
				});
			});
	}

	loadBrands() {
		this.http.get(`${environment.apiUrl}/brand`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.brands = response['results']['data'];
			});
	}

	loadCategories() {
		this.http.get(`${environment.apiUrl}/category`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.categoriesObj = [];
				this.categories = response['results']['data'];
				console.log(this.categories);
				for (const i of this.categories) {
					this.categoriesObj[i._id.toString()] = i.name[this.config.lang];
				}
			});
	}

	loadFeatures() {
		this.http.get(`${environment.apiUrl}/feature`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.features = response['results']['data'];
			});
	}

	loadRanks() {
		this.http.get(`${environment.apiUrl}/rank`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.ranksObj = [];
				this.ranks = response['results']['data'];
				for (const i of this.ranks) {
					this.ranksObj[i._id.toString()] = i.name[this.config.lang];
				}
			});
	}

	loadRanksByCategory() {
		this.http.get(`${environment.apiUrl}/rank`, {
			params: new HttpParams()
				.set('filter', `status=true&category_id=${this.productncategory['category_id']}`)
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.ranksByCategory = response['results']['data'];
			});
	}

	loadRanksByCategoryLocaly(id) {
		return this.ranks ? this.ranks.filter((i) => i.category_id && id && i.category_id.toString() === id.toString()) : [];
	}

	loadStores() {
		this.http.get(`${environment.apiUrl}/store`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.stores = response['results']['data'];
				for (const i of this.stores) {
					this.storesObj[i._id.toString()] = i.name[this.config.lang];
				}
				if (this.item && this.item.prod_n_storeArr) {
					const available_store_ids = this.stores.map((i) => i._id.toString());
					this.item.prod_n_storeArr = this.item.prod_n_storeArr.filter((i) => available_store_ids.indexOf(i['store_id']) !== -1);
				}
			});
	}

	add_productncategory() {
		if (this.item.prod_n_categoryArr === undefined) {
			this.item.prod_n_categoryArr = [];
		}
		this.item.prod_n_categoryArr.push(Object.assign({}, this.productncategory));
		this.productncategory = {};
		this.item.prod_n_categoryArr = this.removeDuplicates(this.item.prod_n_categoryArr, 'category_id');
	}

	add_productnstore() {
		if (this.item.prod_n_storeArr === undefined) {
			this.item.prod_n_storeArr = [];
		}
		this.item.prod_n_storeArr.push(Object.assign({}, this.productnstore));
		this.productnstore = {};
		this.item.prod_n_storeArr = this.removeDuplicates(this.item.prod_n_storeArr, 'store_id');
	}

	add_variantnstore() {
		if (this.variant_product.prod_n_storeArr === undefined) {
			this.variant_product.prod_n_storeArr = [];
		}
		this.variant_product.prod_n_storeArr.push(Object.assign({}, this.variant_n_store));
		this.variant_n_store = {};
		this.variant_product.prod_n_storeArr = this.removeDuplicates(this.variant_product.prod_n_storeArr, 'store_id');
	}

	add_variant_product() {
		if (this.item.variants === undefined) {
			this.item.variants = [];
		}
		this.variant_product.options = [];
		for (const o of Object.keys(this.variant_product_options)) {
			const opt = cloneObject(this.variant_product_options[o]);
			opt.label = opt.option.name;
			delete opt._id;
			delete opt.option;
			delete opt.option_list;
			if (opt.name && opt.label && opt.value) {
				this.variant_product.options.push(opt);
			}
		}
		this.variant_product.sku = this.item.sku;
		this.variant_product.price = parseFloat(this.variant_product.price);
		if (this.variant_product_options) {
			this.variant_product.sku += '-';
			for (const o of this.variant_product_options) {
				this.variant_product.sku += o.sku_code;
			}
			this.item.variants = [...this.item.variants, this.variant_product];
			this.variant_product = {};
		}
		this.item.variants = this.removeDuplicates(this.item.variants, 'sku');
	}

	delete_variant_product(index) {
		this.item.variants.splice(index, 1);
	}

	getBeforeSave() {
		const data = this.item;

		// category
		const prod_in_category = data.prod_n_categoryArr;
		if (data.prod_n_categoryObj === undefined) {
			data.prod_n_categoryObj = {};
		}
		if (prod_in_category) {
			for (const i of prod_in_category) {
				const category = this.categories.find((r) => r._id.toString() === i['category_id'].toString());
				if (category) {
					data.prod_n_categoryObj[i['category_id']] = {
						'name': category.name,
						'sorting': i['sorting'],
					};
				}
			}
		}
		// category

		// store
		const prod_in_store = data.prod_n_storeArr;
		if (data.prod_n_categoryObj === undefined) {
			data.prod_n_categoryObj = {};
		}
		if (prod_in_store) {
			for (const i of prod_in_store) {
				data.prod_n_categoryObj[i['store_id']] = {
					'quantity': i['quantity'],
					'status': i['status'],
				};
			}
		}

		// store
		return JSON.parse(this.safeStringify(data));
	}

	feed_from_store(store_id) {
		const store = this.item && this.item.prod_n_storeArr && this.item.prod_n_storeArr.find((i) => i['store_id'] == store_id);
		return store && store['quantity'] ? parseInt(store['quantity']) : 0;
	}

	getEmptyErrors() {
		return new ProductError();
	}

	updateCnB(rank_id) {
		this.item.prod_n_categoryArr = this.deleteFromObj(this.item.prod_n_categoryArr, 'rank_id', rank_id);
	}

	updatePnS(store_id) {
		this.item.prod_n_storeArr = this.deleteFromObj(this.item.prod_n_storeArr, 'store_id', store_id);
	}

	updateVnS(store_id) {
		this.variant_product.prod_n_storeArr = this.deleteFromObj(this.variant_product.prod_n_storeArr, 'store_id', store_id);
	}

	update_softcode() {
		this.item.soft_code = this.item.sku;
	}

	upload_multi(files: File[]) {
		// pick from one of the 4 styles of file uploads below
		this.upload_multiAndProgress(files);
	}

	upload_multiAndProgress(files: File[]) {
		this.item.gallery_pictures = this.item.gallery_pictures || [];
		for (const _file of files) {
			const formData = new FormData();
			const file: File = _file;
			Array.from([file]).forEach(f => formData.append('picture', f));

			this.http.post(`${environment.apiUrl}/upload`, formData, { reportProgress: true, observe: 'events' })
				.subscribe((event: any) => {
					if (event.type === HttpEventType.UploadProgress) {
					} else if (event instanceof HttpResponse) {
						this.item.gallery_pictures.push(event.body.results.path);
					}
				});
		}
	}

	upload_multi_variant(files: File[]) {
		this.variant_product.gallery_pictures = this.variant_product.gallery_pictures || [];
		for (const _file of files) {
			const formData = new FormData();
			const file: File = _file;
			Array.from([file]).forEach(f => formData.append('picture', f));

			this.http.post(`${environment.apiUrl}/upload`, formData, { reportProgress: true, observe: 'events' })
				.subscribe((event: any) => {
					if (event.type === HttpEventType.UploadProgress) {
					} else if (event instanceof HttpResponse) {
						this.variant_product.gallery_pictures.push(event.body.results.path);
					}
				});
		}
	}

	deletePicture(pic) {
		this.item.gallery_pictures = this.item.gallery_pictures.filter(function (value, index, arr) {
			return pic !== value;
		});
	}

	deletePicture_variant_product(pic) {
		this.variant_product.gallery_pictures = this.variant_product.gallery_pictures.filter(function (value, index, arr) {
			return pic !== value;
		});
	}

	// safely handles circular references
	safeStringify(obj, indent = 2) {
		let cache = [];
		const retVal = JSON.stringify(
			obj,
			(key, value) =>
				typeof value === "object" && value !== null
					? cache.includes(value)
						? undefined // Duplicate reference found, discard key
						: cache.push(value) && value // Store value in our collection
					: value,
			indent
		);
		cache = null;
		return retVal;
	};

	setValidUntilTime() {
		console.log(this.item.discount_price_valid_until);
		this.valid_until_time.hours = new Date().getHours();
		this.valid_until_time.minutes = new Date().getMinutes();
	}

	timeChangedHandeler() {
		this.item.discount_price_valid_until.setHours(this.valid_until_time.hours as number, this.valid_until_time.minutes as number);
		console.log(this.item.discount_price_valid_until);
	}

}
