// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	//apiUrl: 'http://138.197.179.5:3001/v1',
	apiUrl: 'https://seller.jm3eia.com/v1',
	mediaUrl: 'https://media.jm3eia.com/',
	logoUrl: 'https://jm3eia.com/assets/img/logo.png',
	allow_managing_quantities: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
