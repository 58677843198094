import * as _ from "lodash";
import { Adminmember } from 'src/app/pages/adminmember/adminmember.model';

export function cloneArray(data) {
	return _.cloneDeep(data);
};

export function cloneObject(data) {
	return _.cloneDeep(data);
};

export function adminUser(): Adminmember {
	try {
		let _user = localStorage.getItem('auth.user');
		let __user = _user = _user ? JSON.parse(_user) : {};
		return __user as Adminmember;
	} catch (error) {
		return null;
	}
};

