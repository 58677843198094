import { LanguagesObject } from '../../@core/data/languages-object';
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';

export class VariantOption {

}

export class Variant {

}

export class Store implements ITable {

	_id: String = '';
	name: LanguagesObject = new LanguagesObject();
	status: Boolean = false;

}

export class Product implements ITable {

	_id: String = '';
	name: LanguagesObject = new LanguagesObject();
	contents: LanguagesObject = new LanguagesObject();
	description: LanguagesObject = new LanguagesObject();
	keywords: LanguagesObject = new LanguagesObject();
	variants: Array<Object> = [];
	country_id: String = '';
	rank_id: String = '';
	brand_id: String = '';
	feature_id: String = '';
	feature_sorting: Number = 0;
	code: String = '';
	sku: String = '';
	soft_code: String = '';
	uom: String = '';
	barcode: String = '';
	price: Number = 0;
	old_price: Number = 0;
	max_quantity_cart: Number = 0;
	weight: Number = 0;
	picture: String = '';
	gallery_pictures: Array<String> = [];
	status: Boolean = false;
	prod_n_categoryObj: Object = {};
	prod_n_categoryArr: Array<Object> = [];
	prod_n_storeObj: Object = {};
	prod_n_storeArr: Array<Object> = [];
	discount_price_valid_until: Date | null = null;
	show_discount_percentage: Boolean = false;
	free_shipping: Boolean = false;
}

export class ProductError implements IError {

	name: LanguagesObject = new LanguagesObject();
	contents: LanguagesObject = new LanguagesObject();
	description: LanguagesObject = new LanguagesObject();
	keywords: LanguagesObject = new LanguagesObject();
	country_id: String = '';
	rank_id: String = '';
	brand_id: String = '';
	feature_id: String = '';
	feature_sorting: String = '';
	sku: String = '';
	soft_code: String = '';
	uom: String = '';
	barcode: String = '';
	price: String = '';
	old_price: String = '';
	max_quantity_cart: String = '';
	weight: String = '';
	picture: String = '';
	gallery_pictures: String = '';
	status: String = '';
	discount_price_valid_until: String = "";
	show_discount_percentage: String = "";
	free_shipping: Boolean = false;

}

export class ProductNCategory {
	category_id: String = '';
	rank_id: String = '';
}

export class ProductNCategoryError implements IError {
	category_id: String = '';
	rank_id: String = '';
	sorting: String = '';
}

export class ProductNStoreError implements IError {
	inventory_id: String = '';
	feed_from_inventory_id: String = '';
	quantity: String = '';
	status: String = '';
}

export class ProductFilter {
	name?: string = null;
	brand_id?: string = null;
	category_id?: string = null;
	rank_id?: string = null;
	out_of_stock_inventory_id?: string = null;
}
