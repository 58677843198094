import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Setting, SettingError } from "./setting.model";
import { SettingService } from "./setting.service";
import { i18n } from "../../@core/libraries/i18n";
import { HttpClient, HttpEventType, HttpParams, HttpResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MessageComponent } from "src/app/@theme/message/message.component";
import * as moment from "moment";
import { LanguagesService } from "src/app/@core/data/languages.service";
import { City } from "../city/city.model";

@Component({
	selector: "app-setting",
	templateUrl: "./setting.component.html",
	styleUrls: ["./setting.component.scss"],
})
export class SettingComponent implements OnInit {
	model = "setting";
	item: Setting = new Setting();
	errors: SettingError = new SettingError();
	validate: SettingError = new SettingError();
	min_delivery_times: Array<any> = [];

	days: Array<any> = [];
	times: Array<any> = [];
	cities: City[] = [];
	city = "";
	validate_city: String = "";
	citiesObj: any[] = [];
	languages: any;
	isLoading = false;

	percentDone: Number = 0;
	uploadSuccess: boolean;

	public readonly config = {
		lang: localStorage.getItem("lang") || "en",
	};

	constructor(
		protected theService: SettingService,
		public http: HttpClient,
		public _snackBar: MatSnackBar,
		protected router: Router,
		private languagesService: LanguagesService
	) {
		this.languages = this.languagesService.get();
	}
	ngOnInit(): void {
		this.loadCities();
		this.isLoading = true;
		const item = new Setting();

		this.days = moment.weekdays().map((i, idx) => {
			return {
				id: idx,
				text: i,
			};
		});
		for (let i = 0; i < 24; i++) {
			this.times.push({
				id: i,
				text: `${i18n.from} ${i.toString().padStart(2, "0")} ${
					i18n.to
				} ${(i + 1).toString().padStart(2, "0")}`,
			});
		}

		let available_times = {};
		for (const i of this.times) {
			available_times[i.id] = {
				id: i.id,
				is_available: false,
				max_orders: 0,
			};
		}

		this.item.available_delivery_times = [];

		for (const i of this.days) {
			this.item.available_delivery_times[i.id] = available_times;
		}

		this.theService.getAll().subscribe((response) => {
			for (const k of Object.keys(response["results"])) {
				item[k] = response["results"][k];
			}
			this.item = item;
			this.isLoading = false;
		});
		this.min_delivery_times = [];
		for (let i = 0; i <= 6; i++) {
			this.min_delivery_times.push(i * 30);
		}

		for (let i = 4; i <= 24; i++) {
			this.min_delivery_times.push(i * 60);
		}
	}

	time_convert(num) {
		const hours = ("0" + Math.floor(num / 60)).slice(-2);
		const minutes = ("0" + (num % 60)).slice(-2);
		return `${hours}:${minutes}`;
	}

	save() {
		const item = this.item;
		//item.phones = typeof item.phones === 'string' ? item.phones.split(',') : item.phones;
		this.http.put(`${environment.apiUrl}/${this.model}`, item).subscribe(
			(response: any) => {
				if (response.errors) {
					return this.showMessage(i18n.unexpected_error);
				}
				return this.showMessage(i18n.saved_done);
			},
			(e) => {}
		);
	}

	showMessage(message) {
		this._snackBar.openFromComponent(MessageComponent, {
			duration: 5 * 1000,
			data: message,
		});
	}

	loadCities() {
		this.http
			.get(`${environment.apiUrl}/city`, {
				params: new HttpParams()
					.set("filter", `status=true`)
					//.set('sort', `name.${this.config.lang}=asc`)
					.set("limit", "999"),
			})
			.subscribe((response) => {
				this.cities = response["results"]["data"];
				console.log(this.cities);
				for (const i of this.cities) {
					this.citiesObj[i._id.toString()] = i.name[this.config.lang];
				}
			});
	}

	upload(files: File[], picture = 'picture') {
		// pick from one of the 4 styles of file uploads below
		this.uploadAndProgress(files, picture);
	}

	uploadAndProgress(files: File[], picture = 'picture') {
		this.uploadImagesAndProgress(files, picture).then((res: any) => {
			if (picture.includes('.')) {
				const arr = picture.split('.');
				if (!this.item[arr[0]]) {
					this.item[arr[0]] = {};
				}
				this.item[arr[0]][arr[1]] = res.path;
			} else {
				this.item[picture] = res.path;
			}
		});
	}

	uploadImagesAndProgress(files: File[], elm: string) {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			Array.from(files).forEach(f => formData.append('picture', f));
			this.http.post(`${environment.apiUrl}/upload`, formData, { reportProgress: true, observe: 'events' })
				.subscribe((event: any) => {
					if (event.type === HttpEventType.UploadProgress) {
						this.percentDone = Math.round(100 * event.loaded / event.total);
					} else if (event instanceof HttpResponse) {
						this.uploadSuccess = true;
						resolve(event.body.results);
					}
				});
		});
	}

	add_city() {
		if (this.item.cities === undefined) {
			this.item.cities = [];
		}
		if (!this.item.cities.find((c) => c == this.city)) {
			this.item.cities.push(this.city);
		}
		this.city = "";
	}

	remove_city(city) {
		this.item.cities = this.item.cities.filter((c) => c !== city);
	}

	add_all_cities() {
		this.item.cities = this.cities.map((c) => c._id);
	}

	remove_all_cities() {
		this.item.cities = [];
	}
}
