import { IError } from "src/app/@core/libraries/interfaces/error";
import { LanguagesObject } from "src/app/@core/data/languages-object";

export class AvailableDeliveryTime {
	id: Number = 0;
	is_available: Boolean = false;
	max_orders: Number = 0;
}
export class Setting {
	_id: String = "";
	name: LanguagesObject = new LanguagesObject();
	description: LanguagesObject = new LanguagesObject();
	delivery_time: Number = 0;
	delivery_time_text: String = "";
	min_order: Number = 0;
	shipping_cost: Number = null;
	allow_cod: Boolean = false;
	cities: Array<any> = [];
	working_times: Array<any> = [];
	is_external: Boolean = false;
	available_delivery_times: Array<any> = [];
	email: String = null;
	password: String = null;
	inventory_id: String = "";
	picture: String = "";
}

export class SettingError implements IError {
	name: LanguagesObject = new LanguagesObject();
	description: LanguagesObject = new LanguagesObject();
	delivery_time: String = "";
	delivery_time_text: String = "";
	min_order: String = "";
	shipping_cost: String = "";
	allow_cod: String = "";
	working_times: String = "";
	cities: String = "";
	is_external: String = "";
	available_delivery_times: "";
	email: String = "";
	password: String = "";
	inventory_id: String = "";
	picture: String = "";
}
