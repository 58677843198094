import { Component } from '@angular/core';
import { Feature, FeatureError } from '../feature.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'feature';
	item: Feature = new Feature();
	errors: FeatureError = new FeatureError();
	validate: FeatureError = new FeatureError();

	getEmptyErrors() {
		return new FeatureError();
	}

}
