<mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
	<mat-progress-spinner color="primary" mode="indeterminate">
	</mat-progress-spinner>
</mat-card>
<mat-card>
	<mat-card-subtitle>{{ 'translated_details' | i18n }}</mat-card-subtitle>
	<mat-card-content>
		<mat-tab-group>
			<mat-tab *ngFor="let l of languages">
				<ng-template mat-tab-label>
					<i class="flag-icon flag-icon-{{ l.image }}"></i>
					{{ l.name }}
				</ng-template>

				<div class="col-12" dir="{{ l.direction }}">

					<mat-form-field appearance="outline" class="form-group col-md-12">
						<mat-label>{{ 'name' | i18n }} ( {{ l.name }} )</mat-label>
						<input matInput [(ngModel)]="item.name[l.code]" [errorStateMatcher]='validate.name[l.code]'
							placeholder="{{ 'name' | i18n }}" />
						<mat-error>{{ errors.name[l.code] }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-12">
						<mat-label>{{ 'description' | i18n }} ( {{ l.name }} )
						</mat-label>
						<textarea matInput [(ngModel)]="item.description[l.code]"
							[errorStateMatcher]='validate?.description[l.code]'
							placeholder="{{ 'description' | i18n }}"></textarea>
						<mat-error>{{ errors.description[l.code] }}</mat-error>
					</mat-form-field>

				</div>

			</mat-tab>
		</mat-tab-group>
	</mat-card-content>
</mat-card>
<mat-divider></mat-divider>

<mat-card>
	<mat-card-subtitle>{{ 'other_details' | i18n }}</mat-card-subtitle>
	<mat-card-content>
		<div class="row">

			<mat-form-field appearance="outline" class="form-group col-md-6">
				<mat-label>{{ 'delivery_time' | i18n }}</mat-label>
				<mat-select placeholder="{{ 'delivery_time' | i18n }}"
					[(ngModel)]='item.delivery_time' [errorStateMatcher]='validate.delivery_time' name="delivery_time">
					<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
					<mat-option *ngFor="let dt of min_delivery_times" [value]="dt">{{ time_convert(dt) }}
					</mat-option>
				</mat-select>
				<mat-error>{{ errors.delivery_time }}</mat-error>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-6">
				<mat-label>{{ 'delivery_time_text' | i18n }}</mat-label>
				<input matInput [(ngModel)]="item.delivery_time_text" [errorStateMatcher]='validate.delivery_time_text'
					placeholder="{{ 'delivery_time_text' | i18n }}" />
				<mat-error>{{ errors.delivery_time_text }}</mat-error>
			</mat-form-field>

			<!-- <mat-form-field appearance="outline" class="form-group col-md-6">
				<mat-label>{{ 'inventory' | i18n }}</mat-label>
				<mat-select placeholder="{{ 'inventory' | i18n }}"
					[(ngModel)]="item.inventory_id" [errorStateMatcher]='validate.inventory_id'
					name="item.inventory_id">
					<mat-option *ngFor="let inventory of inventories" [value]="inventory._id">
						{{ inventory.name[config.lang] }}
					</mat-option>
				</mat-select>
			</mat-form-field> -->

			<mat-form-field appearance="outline" class="form-group col-md-6">
				<mat-label>{{ 'min_order' | i18n }}</mat-label>
				<input matInput [(ngModel)]="item.min_order" [errorStateMatcher]='validate.min_order'
					placeholder="{{ 'min_order' | i18n }}" />
				<mat-error>{{ errors.min_order }}</mat-error>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-6">
				<mat-label>{{ 'shipping_cost' | i18n }}</mat-label>
				<input matInput [(ngModel)]="item.shipping_cost" [errorStateMatcher]='validate.shipping_cost'
					placeholder="{{ 'shipping_cost' | i18n }}" />
				<mat-error>{{ errors.shipping_cost }}</mat-error>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-6">
				<mat-label>{{ 'allow_cod' | i18n }}</mat-label>
				<mat-select placeholder="{{ 'allow_cod' | i18n }}" [(ngModel)]='item.allow_cod'
					[errorStateMatcher]='validate.allow_cod' name="allow_cod">
					<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
					<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
				</mat-select>
				<mat-error>{{ errors.allow_cod }}</mat-error>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-12 mat-upload">
				<mat-label>{{ 'picture' | i18n }}</mat-label>
				<input type="file" (change)="upload($event.target.files)" />
				{{ 'upload_percent' | i18n }}: {{ percentDone }}%
				<img *ngIf="item.picture" src="{{ environment.mediaUrl }}{{ item.picture }}" width="48" />
				<input matInput hidden [(ngModel)]="item.picture" [errorStateMatcher]='validate.picture' name="picture">
				<br />
				<mat-error>{{ errors.picture }}</mat-error>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-12">
				<mat-label>Is external supplier</mat-label>
				<mat-select placeholder="Is external supplier" [(ngModel)]='item.is_external'
					[errorStateMatcher]='validate.is_external' name="is_external">
					<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
					<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
				</mat-select>
				<mat-error>{{ errors.is_external }}</mat-error>
			</mat-form-field>

			<mat-card *ngIf="item.is_external" class="col-md-12 my-3">
				<mat-card-subtitle>{{ 'cities' | i18n }}</mat-card-subtitle>
				<mat-card-content>
					<div class="row">
						<div class="col-12">
							<button mat-raised-button color="link" (click)="add_all_cities()">
								<mat-icon>check_box</mat-icon>
								{{ 'select_all' | i18n }}
							</button>
							<button mat-raised-button color="link" (click)="remove_all_cities()">
								<mat-icon>check_box_outline_blank</mat-icon>
								{{ 'deselect_all' | i18n }}
							</button>
						</div>
						<mat-form-field appearance="outline" class="form-group col-md-6">
							<mat-label>{{ 'city' | i18n }}</mat-label>
							<mat-select placeholder="{{ 'city' | i18n }}" [(ngModel)]="city"
								[errorStateMatcher]='validate_city' name="city_id">
								<mat-option *ngFor="let c of cities" [value]="c._id">
									{{ c.name[config.lang] }}
								</mat-option>
							</mat-select>
						</mat-form-field>

						<div appearance="outline" class="form-group col-md-6">
							<button mat-raised-button color="primary" [disabled]="!city" (click)="add_city()">
								<mat-icon>add</mat-icon>
								{{ 'add' | i18n }}
							</button>
						</div>

					</div>
					<div class="form-row" *ngIf="item.cities" style="max-height: 300px; overflow-y: scroll;">
						<table class="table table-bordered">
							<tr>
								<td>{{ 'city' | i18n }}</td>
							</tr>
							<tr *ngFor="let p of item.cities; let i = index;">
								<td>{{ citiesObj[p] }}</td>
								<td>
									<button mat-mini-fab color="warn" (click)="remove_city(p)"
										title="{{ 'delete' | i18n }}">
										<mat-icon>delete</mat-icon>
									</button>
								</td>
							</tr>
						</table>
					</div>
				</mat-card-content>
			</mat-card>
			<mat-card *ngIf="item.is_external" class="col-md-12">
				<mat-card-subtitle>{{ 'working_times' | i18n }}</mat-card-subtitle>
				<mat-card-content>
					<div *ngFor="let d of days">
						<div class="container">
							<div class="row">
								<div class="col-md-4"><strong>{{ d.text }}</strong></div>
								<div class="col-md-4">
									<mat-form-field class="form-group" appearance="outline">
										<mat-label>{{ 'from' | i18n }} </mat-label>
										<input type="number" matInput
											[(ngModel)]="this.item.working_times[d.id]['from']" min="0" max="23"
											maxlength="2" placeholder="{{ 'from' | i18n }}" />
									</mat-form-field>
								</div>
								<div class="col-md-4">
									<mat-form-field class="form-group" appearance="outline">
										<mat-label>{{ 'to' | i18n }} </mat-label>
										<input type="number" matInput [(ngModel)]="this.item.working_times[d.id]['to']"
											min="0" max="23" maxlength="2"
											placeholder="{{ 'to' | i18n }}" />
									</mat-form-field>
								</div>
							</div>
						</div>
					</div>
				</mat-card-content>
			</mat-card>

			<div class="col-md-12 row" *ngIf="item.is_external">
				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'email' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.email" [errorStateMatcher]='validate.email'
						placeholder="{{ 'email' | i18n }}" />
					<mat-error>{{ errors.email }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'password' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.password" type="password" [errorStateMatcher]='validate.password'
						placeholder="{{ 'password' | i18n }}" />
					<mat-error>{{ errors.password }}</mat-error>
				</mat-form-field>
			</div>

			<mat-accordion class="col-md-12" *ngFor="let d of days">
				<mat-expansion-panel>
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ d.text }}
						</mat-panel-title>
						<mat-panel-description>
						</mat-panel-description>
					</mat-expansion-panel-header>

					<div class="container">
						<div class="row">
							<div class="col-md-12" *ngFor="let p of times">
								<div class="row">
									<div class="col-md-3">
										<div appearance="outline" class="form-group">
											<mat-label>{{ 'enable' | i18n }} </mat-label>
											<mat-slide-toggle
												[(ngModel)]="item.available_delivery_times[d.id][p.id]['is_enabled']"
												[checked]="item.available_delivery_times[d.id][p.id]['is_enabled']">
											</mat-slide-toggle>
										</div>
									</div>
									<div class="col-md-3">
										{{ p.text }}
									</div>
									<div class="col-md-3">
										<mat-checkbox
											[(ngModel)]="item.available_delivery_times[d.id][p.id]['is_available']">
											{{ 'is_available' | i18n }}</mat-checkbox>
									</div>
									<div class="col-md-3">
										<mat-form-field appearance="outline" class="form-group">
											<mat-label>{{ 'max_orders' | i18n }}</mat-label>
											<input type="number" matInput
												[(ngModel)]="item.available_delivery_times[d.id][p.id]['max_orders']"
												placeholder="{{ 'max_orders' | i18n }}" />
										</mat-form-field>
									</div>
								</div>
							</div>
						</div>
					</div>


				</mat-expansion-panel>
			</mat-accordion>

			<!-- <mat-form-field appearance="outline" class="form-group col-md-6">
				<mat-label>{{ 'status' | i18n }}</mat-label>
				<mat-select placeholder="{{ 'status' | i18n }}" [(ngModel)]='item.status'
					[errorStateMatcher]='validate.status' name="status">
					<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
					<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
				</mat-select>
				<mat-error>{{ errors.status }}</mat-error>
			</mat-form-field> -->
		</div>
	</mat-card-content>

	<mat-card-footer>
		<div mat-dialog-actions class="col-6 col-offset-3">
			<button mat-flat-button color="primary" class="btn-block"
				(click)="save()">{{ 'save' | i18n }}</button>
		</div>
		<br class="my-5" />
	</mat-card-footer>
</mat-card>
