<h1 mat-dialog-title>{{ 'details' | i18n }}</h1>
<div mat-dialog-content *ngIf="item">
	<mat-card>
		<mat-card-subtitle>{{ 'other_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
				<mat-progress-spinner color="primary" mode="indeterminate">
				</mat-progress-spinner>
			</mat-card>
			<div *ngIf="item">
				<mat-card class="col-xs-8 offset-xs-2">
					<mat-card-content>
						<table class="table table-bordered">
							<tbody>
								<tr>
									<td>{{ 'fullname' | i18n }}</td>
									<td>{{ item.user_data.fullname }}</td>
								</tr>
								<tr>
									<td>{{ 'email' | i18n }}</td>
									<td>{{ item.user_data.email }}</td>
								</tr>
								<tr>
									<td>{{ 'mobile' | i18n }}</td>
									<td>{{ item.user_data.mobile }}</td>
								</tr>
								<tr>
									<td>{{ 'city' | i18n }}</td>
									<td>{{ item.user_data.address.city_name }}</td>
								</tr>
								<tr>
									<td>{{ 'address.widget' | i18n }}</td>
									<td>{{ item.user_data.address.widget }}</td>
								</tr>
								<tr>
									<td>{{ 'address.street' | i18n }}</td>
									<td>{{ item.user_data.address.street }}</td>
								</tr>
								<tr>
									<td>{{ 'address.gada' | i18n }}</td>
									<td>{{ item.user_data.address.gada }}</td>
								</tr>
								<tr>
									<td>{{ 'address.house' | i18n }}</td>
									<td>{{ item.user_data.address.house }}</td>
								</tr>
							</tbody>
						</table>
						<mat-divider></mat-divider>
						<div class="table-responsive">
							<table class="table table-bordered">
								<tbody>
									<tr>
										<td>#</td>
										<td>{{ item._id }}</td>
									</tr>
									<tr>
										<td>{{ 'delivery_time' | i18n }}</td>
										<td>{{ item.delivery_time }}</td>
									</tr>
									<tr>
										<td>{{ 'created' | i18n }}</td>
										<td>{{ item.created }}</td>
									</tr>
									<tr>
										<td>{{ 'status' | i18n }}</td>
										<td>
											<div class="row">
												<div class="col-md-6">
													<mat-form-field appearance="outline" class="form-group">
														<mat-select placeholder="{{ 'status' | i18n }}"
															[(ngModel)]="item.status">
															<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
															<mat-option *ngFor="let c of order_status_list"
																[value]="c.key">
																{{c.value}}
															</mat-option>
														</mat-select>
													</mat-form-field>
												</div>
												<div class="col-md-4">
													<button mat-flat-button color="success"
														(click)="updateStatus()">{{ 'update_status' | i18n }}</button>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<mat-divider></mat-divider>
						<table class="table table-bordered">
							<thead>
								<tr>
									<th>
										{{ 'picture' | i18n }}
									</th>
									<th>
										{{ 'name' | i18n }}
									</th>
									<th>
										{{ 'quantity' | i18n }}
									</th>
									<th>
										{{ 'price' | i18n }}
									</th>
									<th>
										{{ 'total' | i18n }}
									</th>
									<th>

									</th>
								</tr>
							</thead>
							<tbody *ngFor="let s of supplier_ids">
								<tr *ngFor="let i of item.products[s]">
									<td><img src="{{ i.picture }}" width="48" /></td>
									<td>
										{{ i.name[config.lang] }}
										<br />
										<span class="text-danger" *ngIf="i.warning"> {{ i.warning }} </span>
									</td>
									<td>

										<button mat-mini-fab color="success" (click)="increase_quantity(i._id)">
											<mat-icon>add_circle</mat-icon>
										</button>

										<mat-form-field appearance="outline" class="form-group col-md-6">
											<mat-label>{{ 'quantity' | i18n }}</mat-label>
											<input matInput [(ngModel)]="i.quantity" name="quantity"
												(change)="updateQuantity(i._id, $event.target.value)" id="q_{{ i._id }}"
												placeholder="{{ 'quantity' | i18n }}" />
										</mat-form-field>

										<button mat-mini-fab color="success" (click)="decrease_quantity(i._id)">
											<mat-icon>remove_circle</mat-icon>
										</button>
										<br />
										<span class="text-danger"
											*ngIf="i.cart_quantity && i.quantity != i.cart_quantity">
											{{ 'cart_quantity' | i18n }} (( {{ i.cart_quantity }} ))</span>
									</td>
									<td>
										{{ i.price }}
									</td>
									<td>
										{{ getRoundedPrice(i.price * i.quantity) }}
									</td>
									<td>
										<button mat-mini-fab color="warn" (click)="deleteProduct(i._id)"
											title="{{ 'delete' | i18n }}">
											<mat-icon>delete</mat-icon>
										</button>
									</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td></td>
									<td>{{ 'subtotal' | i18n }}</td>
									<td></td>
									<td></td>
									<td>{{ item.subtotal }}</td>
									<td></td>
								</tr>
								<tr>
									<td></td>
									<td>{{ 'shipping_cost' | i18n }}</td>
									<td></td>
									<td></td>
									<td>{{ item.shipping_cost }}</td>
									<td></td>
								</tr>
								<tr>
									<td></td>
									<td>{{ 'discount_code' | i18n }}</td>
									<td></td>
									<td></td>
									<td>{{ item.coupon.code }}</td>
									<td></td>
								</tr>
								<tr>
									<td></td>
									<td>{{ 'discount_value' | i18n }}</td>
									<td></td>
									<td></td>
									<td>{{ item.coupon.value }}</td>
									<td></td>
								</tr>
								<tr>
									<td></td>
									<td>{{ 'discount_by_wallet' | i18n }}</td>
									<td></td>
									<td></td>
									<td>{{ item.discount_by_wallet }}</td>
									<td></td>
								</tr>
								<tr>
									<td></td>
									<td>{{ 'discount_by_wallet_value' | i18n }}</td>
									<td></td>
									<td></td>
									<td>{{ item.discount_by_wallet_value }}</td>
									<td></td>
								</tr>
								<tr>
									<td></td>
									<td>{{ 'total' | i18n }}</td>
									<td></td>
									<td></td>
									<td>{{ item.total }}</td>
									<td></td>
								</tr>
								<tr *ngIf="item.cart_total && item.cart_total != item.total" class="bg bg-danger">
									<td></td>
									<td>{{ 'cart_total' | i18n }}</td>
									<td></td>
									<td></td>
									<td>{{ item.cart_total }}</td>
									<td></td>
								</tr>
							</tfoot>
						</table>
						<mat-divider></mat-divider>
						<table class="table table-bordered" *ngIf="item.payment_details">
							<tr *ngFor="let p of payment_details_keys">
								<td>{{ p }}</td>
								<td>{{ item.payment_details[p] }}</td>
							</tr>
						</table>
					</mat-card-content>
				</mat-card>
			</div>
		</mat-card-content>
	</mat-card>
</div>
<div mat-dialog-actions>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
	<button mat-flat-button color="primary" *ngIf="item && !item.issues"
		(click)="createIssue()">{{ 'create_issue' | i18n }}</button>
	<button mat-flat-button color="primary" *ngIf="item && item.issues"
		(click)="openIssues()">{{ 'issues' | i18n }}</button>
	<button mat-flat-button color="primary" (click)="openBill()">{{ 'bill' | i18n }}</button>
</div>
