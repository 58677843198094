import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { OrderService } from './order.service';
import { DetailsComponent } from './details/details.component';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Inventory } from '../inventory/inventory.model';
import { OrderFilter } from './order.model';
import { i18n } from 'src/app/@core/libraries/i18n';
import { Member } from '../member/member.model';
import { Adminmember } from '../adminmember/adminmember.model';
import { adminUser } from 'src/app/@core/libraries/healper';

@Component({
	selector: 'app-order',
	templateUrl: './order.component.html',
	styleUrls: ['./order.component.scss']
})
export class OrderComponent extends BaseIndex {

	model = 'order';
	displayedColumns: string[] = ['select', '_id', 'name', 'total', 'delivery_time', 'created', 'status', 'tools'];
	theDetailsComponent = DetailsComponent;
	theFormComponent = null;
	searchForm: Boolean = false;
	inventories: Inventory[] = [];
	order_status_list: Array<Object> = [];
	filter: OrderFilter = new OrderFilter();
	canDelete: Boolean = false;

	constructor(protected theService: OrderService,
		public http: HttpClient,
		protected router: Router, protected dialog: MatDialog) {
		super(theService, router, dialog);

		this.canDelete = adminUser().group == 'admin';

		this.loadStores();
		this.order_status_list = Object.keys(i18n.order_status_list).map((k) => {
			return {
				key: k,
				value: i18n.order_status_list[k],
			};
		});
	}

	afterCloseDetails() {
		this.get();
	}

	loadStores() {
		this.http.get(`${environment.apiUrl}/inventory`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.inventories = response['results']['data'];
			});
	}

	openBill(id) {
	}

	setSearchForm() {
		this.searchForm = !this.searchForm;
	}

}
