<h1 mat-dialog-title>{{ data.action | i18n }}</h1>
<div mat-dialog-content>

	<mat-tab-group>

		<mat-tab>
			<ng-template mat-tab-label>
				{{ 'product' | i18n }}
			</ng-template>
			<div class="col-12">

				<mat-card>
					<mat-card-subtitle>{{ 'translated_details' | i18n }}</mat-card-subtitle>
					<mat-card-content>
						<mat-tab-group>
							<mat-tab *ngFor="let l of languages">
								<ng-template mat-tab-label>
									<i class="flag-icon flag-icon-{{l.image}}"></i>
									{{l.name}}
								</ng-template>
								<div class="col-12" dir="{{l.direction}}">

									<mat-form-field appearance="outline" class="form-group col-md-12">
										<mat-label>{{ 'name' | i18n }} ( {{ l.name }} )</mat-label>
										<input matInput [(ngModel)]="item.name[l.code]"
											[errorStateMatcher]='validate.name[l.code]'
											placeholder="{{ 'name' | i18n }}" />
										<mat-error>{{ errors.name[l.code] }}</mat-error>
									</mat-form-field>

									<mat-form-field appearance="outline" class="form-group col-md-12">
										<mat-label>{{ 'contents' | i18n }} ( {{ l.name }} )</mat-label>
										<textarea matInput [(ngModel)]="item.contents[l.code]"
											[errorStateMatcher]='validate?.contents[l.code]'
											placeholder="{{ 'contents' | i18n }}"></textarea>
										<!-- <ckeditor [(ngModel)]="item.contents[l.code]" [editor]="Editor"></ckeditor> -->
										<mat-error>{{ errors.contents[l.code] }}</mat-error>
									</mat-form-field>

									<mat-form-field appearance="outline" class="form-group col-md-12">
										<mat-label>{{ 'description' | i18n }} ( {{ l.name }} )</mat-label>
										<textarea matInput [(ngModel)]="item.description[l.code]"
											[errorStateMatcher]='validate?.description[l.code]'
											placeholder="{{ 'description' | i18n }}"></textarea>
										<mat-error>{{ errors.description[l.code] }}</mat-error>
									</mat-form-field>

									<mat-form-field appearance="outline" class="form-group col-md-12">
										<mat-label>{{ 'keywords' | i18n }} ( {{ l.name }} )</mat-label>
										<textarea matInput [(ngModel)]="item.keywords[l.code]"
											[errorStateMatcher]='validate?.keywords[l.code]'
											placeholder="{{ 'keywords' | i18n }}"></textarea>
										<mat-error>{{ errors.keywords[l.code] }}</mat-error>
									</mat-form-field>

								</div>
							</mat-tab>
						</mat-tab-group>
					</mat-card-content>
				</mat-card>
				<mat-divider></mat-divider>

				<mat-card>
					<mat-card-subtitle>{{ 'other_details' | i18n }}</mat-card-subtitle>
					<mat-card-content>
						<div class="row">

							<mat-form-field appearance="outline" class="form-group col-md-6">
								<mat-label>{{ 'sku' | i18n }}</mat-label>
								<input matInput [(ngModel)]="item.sku" [errorStateMatcher]='validate.sku'
									[disabled]="item.variants && item.variants.length > 0"
									placeholder="{{ 'sku' | i18n }}" (keyup)="update_softcode()" />
								<mat-error>{{ errors.sku }}</mat-error>
							</mat-form-field>

							<mat-form-field appearance="outline" class="form-group col-md-6">
								<mat-label>{{ 'soft_code' | i18n }}</mat-label>
								<input matInput [(ngModel)]="item.soft_code" [errorStateMatcher]='validate.soft_code'
									placeholder="{{ 'soft_code' | i18n }}" />
								<mat-error>{{ errors.soft_code }}</mat-error>
							</mat-form-field>

							<mat-form-field appearance="outline" class="form-group col-md-6">
								<mat-label>{{ 'uom' | i18n }}</mat-label>
								<input matInput [(ngModel)]="item.uom" [errorStateMatcher]='validate.uom'
									placeholder="{{ 'uom' | i18n }}" />
								<mat-error>{{ errors.uom }}</mat-error>
							</mat-form-field>

							<mat-form-field appearance="outline" class="form-group col-md-6">
								<mat-label>{{ 'barcode' | i18n }}</mat-label>
								<input matInput [(ngModel)]="item.barcode" [errorStateMatcher]='validate.barcode'
									placeholder="{{ 'barcode' | i18n }}" />
								<mat-error>{{ errors.barcode }}</mat-error>
							</mat-form-field>

							<mat-form-field appearance="outline" class="form-group col-md-6">
								<mat-label>{{ 'weight' | i18n }}</mat-label>
								<input matInput [(ngModel)]="item.weight" [errorStateMatcher]='validate.weight'
									placeholder="{{ 'weight' | i18n }}" />
								<mat-error>{{ errors.weight }}</mat-error>
							</mat-form-field>

							<mat-form-field appearance="outline" class="form-group col-md-6">
								<mat-label>{{ 'price' | i18n }}</mat-label>
								<input matInput [(ngModel)]="item.price" [errorStateMatcher]='validate.price'
									placeholder="{{ 'price' | i18n }}" />
								<mat-error>{{ errors.price }}</mat-error>
							</mat-form-field>

							<mat-form-field *ngIf="item.old_price" appearance="outline" class="form-group col-md-4">
								<mat-label>{{ 'discount_price_valid_until' | i18n }}</mat-label>
								<input matInput [min]="minDate" [matDatepicker]="picker" [(ngModel)]="item.discount_price_valid_until"
									[errorStateMatcher]='validate.discount_price_valid_until' (dateChange)="setValidUntilTime()" placeholder="{{ 'discount_price_valid_until' | i18n }}" />
								<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
								<mat-datepicker #picker></mat-datepicker>
								<mat-error>{{ errors.discount_price_valid_until }}</mat-error>
							</mat-form-field>

							<mat-form-field *ngIf="item.discount_price_valid_until" appearance="outline" class="form-group col-md-1">
								<mat-label>{{ 'hours' | i18n }}</mat-label>
								<input type="number" matInput [(ngModel)]="valid_until_time.hours" (change)="timeChangedHandeler()" min="0" max="23" maxlength="2"
									placeholder="{{ 'hours' | i18n }}" />
							</mat-form-field>

							<mat-form-field *ngIf="item.discount_price_valid_until" appearance="outline" class="form-group col-md-1">
								<mat-label>{{ 'minutes' | i18n }}</mat-label>
								<input type="number" matInput [(ngModel)]="valid_until_time.minutes" (change)="timeChangedHandeler()" min="0" max="59" maxlength="2"
									placeholder="{{ 'minutes' | i18n }}" />
							</mat-form-field>

							<mat-form-field appearance="outline" class="form-group col-md-6">
								<mat-label>{{ 'old_price' | i18n }}</mat-label>
								<input matInput [(ngModel)]="item.old_price" [errorStateMatcher]='validate.old_price'
									placeholder="{{ 'old_price' | i18n }}" />
								<mat-error>{{ errors.old_price }}</mat-error>
							</mat-form-field>

							<mat-form-field appearance="outline" class="form-group col-md-6">
								<mat-label>{{ 'show_discount_percentage' | i18n }}</mat-label>
								<mat-select placeholder="{{ 'show_discount_percentage' | i18n }}" [(ngModel)]='item.show_discount_percentage'
									[errorStateMatcher]='validate.show_discount_percentage' name="show_discount_percentage">
									<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
									<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
								</mat-select>
								<mat-error>{{ errors.show_discount_percentage }}</mat-error>
							</mat-form-field>

							<mat-form-field appearance="outline" class="form-group col-md-6">
								<mat-label>{{ 'max_quantity_cart' | i18n }}</mat-label>
								<input type="number" matInput [(ngModel)]="item.max_quantity_cart"
									[errorStateMatcher]='validate.max_quantity_cart'
									placeholder="{{ 'max_quantity_cart' | i18n }}" />
								<mat-error>{{ errors.max_quantity_cart }}</mat-error>
							</mat-form-field>

							<mat-form-field appearance="outline" class="form-group col-md-6">
								<mat-label>{{ 'brand' | i18n }}</mat-label>
								<mat-select placeholder="{{ 'brand' | i18n }}" [(ngModel)]="item.brand_id"
									[errorStateMatcher]='validate.brand_id'>
									<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
									<mat-option *ngFor="let c of brands" [value]="c._id">
										{{c.name[config.lang]}}
									</mat-option>
								</mat-select>
							</mat-form-field>

							<mat-form-field appearance="outline" class="form-group col-md-6">
								<mat-label>{{ 'free_shipping' | i18n }}</mat-label>
								<mat-select placeholder="{{ 'free_shipping' | i18n }}" [(ngModel)]='item.free_shipping'
									[errorStateMatcher]='validate.free_shipping' name="free_shipping">
									<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
									<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
								</mat-select>
								<mat-error>{{ errors.free_shipping }}</mat-error>
							</mat-form-field>

							<mat-form-field appearance="outline" class="form-group col-md-6">
								<mat-label>{{ 'feature' | i18n }}</mat-label>
								<mat-select placeholder="{{ 'feature' | i18n }}" [(ngModel)]="item.feature_id"
									[errorStateMatcher]='validate.feature_id'>
									<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
									<mat-option *ngFor="let c of features"
										[value]="c._id">
										{{c.name[config.lang]}}
									</mat-option>
								</mat-select>
							</mat-form-field>

							<mat-form-field appearance="outline" class="form-group col-md-6">
								<mat-label>{{ 'feature_sorting' | i18n }}</mat-label>
								<input type="number" matInput [(ngModel)]="item.feature_sorting"
									[errorStateMatcher]='validate.feature_sorting'
									placeholder="{{ 'feature_sorting' | i18n }}" />
								<mat-error>{{ errors.feature_sorting }}</mat-error>
							</mat-form-field>

							<mat-form-field appearance="outline" class="form-group col-md-6">
								<mat-label>{{ 'status' | i18n }}</mat-label>
								<mat-select placeholder="{{ 'status' | i18n }}" [(ngModel)]='item.status'
									[errorStateMatcher]='validate.status' name="status">
									<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
									<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
								</mat-select>
								<mat-error>{{ errors.status }}</mat-error>
							</mat-form-field>

							<mat-form-field appearance="outline" class="form-group col-md-12 mat-upload">
								<mat-label>{{'picture' | i18n}}</mat-label>
								<input type="file" (change)="upload($event.target.files)" />
								{{'upload_percent' | i18n}}: {{percentDone}}%
								<img *ngIf="item.picture" src="{{environment.mediaUrl}}{{item.picture}}" width="48" />
								<input matInput hidden [(ngModel)]="item.picture" [errorStateMatcher]='validate.picture'
									name="picture">
								<br />
								<mat-error>{{ errors.picture }}</mat-error>
							</mat-form-field>

							<mat-form-field appearance="outline" class="form-group col-md-12 mat-upload">
								<mat-label>{{'gallery_pictures' | i18n}}</mat-label>
								<input type="file" (change)="upload_multi($event.target.files)" multiple />
								<input matInput hidden [(ngModel)]="item.gallery_pictures" name="gallery_pictures">
							</mat-form-field>

							<fieldset appearance="outline" class="form-group col-md-12 mat-multi-upload">
								<div class="col-3" *ngFor="let pic of item.gallery_pictures">
									<img src="{{environment.mediaUrl}}{{pic}}" height="48" />
									<button mat-mini-fab color="warn" (click)="deletePicture(pic)"
										title="{{ 'delete' | i18n }}">
										<mat-icon>delete</mat-icon>
									</button>
								</div>
							</fieldset>

						</div>

						<mat-card>
							<mat-card-subtitle>{{'sorting_n_categories' | i18n}}</mat-card-subtitle>
							<mat-card-content>
								<div class="row">

									<mat-form-field appearance="outline" class="form-group col-md-6">
										<mat-label>{{ 'category' | i18n }}</mat-label>
										<mat-select placeholder="{{ 'category' | i18n }}"
											[(ngModel)]="productncategory['category_id']"
											[errorStateMatcher]='validate_productncategory["category_id"]'
											name="category_id" (selectionChange)="loadRanksByCategory()">
											<mat-option *ngFor="let c of categories"
												[value]="c._id">
												{{c.name[config.lang]}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="outline" class="form-group col-md-6">
										<mat-label>{{ 'rank' | i18n }}</mat-label>
										<mat-select placeholder="{{ 'rank' | i18n }}"
											[(ngModel)]="productncategory['rank_id']"
											[errorStateMatcher]='validate_productncategory["rank_id"]' name="rank_id">
											<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
											<mat-option *ngFor="let r of ranksByCategory" [value]="r._id">
												{{r.name[config.lang]}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="outline" class="form-group col-md-6">
										<mat-label>{{ 'sorting' | i18n }}</mat-label>
										<input matInput [(ngModel)]="productncategory['sorting']"
											[errorStateMatcher]='validate_productncategory["sorting"]' name="sorting"
											placeholder="{{ 'sorting' | i18n }}" />
									</mat-form-field>

									<div class="form-group col-md-6">
										<button mat-raised-button color="primary"
											[disabled]="!productncategory['category_id'] || !productncategory['sorting']"
											(click)="add_productncategory()">
											<mat-icon>add</mat-icon>
											{{ 'add' | i18n }}
										</button>
									</div>
								</div>
								<div class="form-row"
									*ngIf="item.prod_n_categoryArr && item.prod_n_categoryArr.length > 0">
									<table class="table table-bordered">
										<tr>
											<td>{{ 'category' | i18n }}</td>
											<td>{{ 'rank' | i18n }}</td>
											<td>{{ 'sorting' | i18n }}</td>
											<td></td>
										</tr>
										<tr *ngFor="let p of item.prod_n_categoryArr; let i = index;">
											<td>{{ categoriesObj[p['category_id']] }}</td>
											<td>
												<mat-form-field appearance="outline" class="form-group col-md-12">
													<mat-label>{{ 'rank' | i18n }}</mat-label>
													<mat-select placeholder="{{ 'rank' | i18n }}"
														[(ngModel)]="p['rank_id']" name="rank_id">
														<mat-option
															*ngFor="let r of loadRanksByCategoryLocaly(p['category_id'])"
															[value]="r._id">
															{{r.name[config.lang]}}
														</mat-option>
													</mat-select>
												</mat-form-field>
											</td>
											<td>
												<mat-form-field appearance="outline" class="form-group col-md-12">
													<mat-label>{{ 'sorting' | i18n }}</mat-label>
													<input matInput [(ngModel)]="item.prod_n_categoryArr[i]['sorting']"
														placeholder="{{ 'sorting' | i18n }}" />
												</mat-form-field>
											</td>
											<td>
												<button mat-mini-fab color="warn" (click)="updateCnB(p.rank_id)"
													title="{{ 'delete' | i18n }}">
													<mat-icon>delete</mat-icon>
												</button>
											</td>
										</tr>
									</table>
								</div>
							</mat-card-content>
						</mat-card>

						<mat-card>
							<mat-card-subtitle>{{ 'quantities_n_stores' | i18n }}
							</mat-card-subtitle>
							<mat-card-content>
								<div class="row" *ngIf="allow_managing_quantities">

									<mat-form-field appearance="outline" class="form-group col-md-6">
										<mat-label>{{ 'store' | i18n }}</mat-label>
										<mat-select placeholder="{{ 'store' | i18n }}" [(ngModel)]="productnstore['store_id']"
											[errorStateMatcher]='validate_productnstore["store_id"]' name="store_id">
											<mat-option *ngFor="let c of stores" [value]="c._id">
												{{ c.name[config.lang] }}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="outline" class="form-group col-md-6">
										<mat-label>{{ 'feed_from_store' | i18n }}</mat-label>
										<mat-select placeholder="{{ 'feed_from_store' | i18n }}"
											[(ngModel)]="productnstore['feed_from_store_id']"
											[errorStateMatcher]='validate_productnstore["feed_from_store_id"]' name="feed_from_store_id">
											<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
											<mat-option *ngFor="let c of stores" [value]="c._id">
												{{ c.name[config.lang] }}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="outline" *ngIf="!productnstore['feed_from_store_id']" class="form-group col-md-6">
										<mat-label>{{ 'quantity' | i18n }}</mat-label>
										<input matInput [(ngModel)]="productnstore['quantity']"
											[errorStateMatcher]='validate_productnstore["quantity"]' name="quantity"
											placeholder="{{ 'quantity' | i18n }}" />
									</mat-form-field>

									<div appearance="outline" class="form-group col-md-6">
										<mat-label>{{ 'status' | i18n }}</mat-label>
										<mat-slide-toggle [(ngModel)]="productnstore['status']" [checked]="productnstore['status']">
										</mat-slide-toggle>
										<mat-error>{{ errors.status }}</mat-error>
									</div>

									<div appearance="outline" class="form-group col-md-6">
										<button mat-raised-button color="primary"
											[disabled]="!productnstore['store_id'] || ((!productnstore['quantity'] || +productnstore['quantity'] <= 0) && !productnstore['feed_from_store_id']) || productnstore['status'] === null"
											(click)="add_productnstore()">
											<mat-icon>add</mat-icon>
											{{ 'add' | i18n }}
										</button>
									</div>

								</div>
								<div class="form-row" *ngIf="item.prod_n_storeArr">
									<table class="table table-bordered">
										<tr>
											<td>{{ 'store' | i18n }}</td>
											<td>{{ 'quantity' | i18n }}</td>
											<td>{{ 'status' | i18n }}</td>
										</tr>
										<tr *ngFor="let p of item.prod_n_storeArr; let i = index;">
											<td>{{ storesObj[p['store_id']] }}</td>
											<td>
												<mat-form-field appearance="outline" *ngIf="!item.prod_n_storeArr[i]['feed_from_store_id']"
													class="form-group col-md-12">
													<mat-label>{{ 'quantity' | i18n }}
													</mat-label>
													<input matInput [(ngModel)]="item.prod_n_storeArr[i]['quantity']"
														[readonly]="!allow_managing_quantities"
														placeholder="{{ 'quantity' | i18n }}" />
												</mat-form-field>
												<mat-form-field appearance="outline" *ngIf="item.prod_n_storeArr[i]['feed_from_store_id']"
													class="form-group col-md-12">
													<mat-label>{{ 'quantity' | i18n }}
													</mat-label>
													<input matInput [readonly]="true"
														[value]="feed_from_store(item.prod_n_storeArr[i]['feed_from_store_id'])"
														placeholder="{{ 'quantity' | i18n }}" />
												</mat-form-field>
											</td>
											<td>
												<mat-slide-toggle [(ngModel)]="item.prod_n_storeArr[i]['status']"
													[disabled]="!allow_managing_quantities" [checked]="item.prod_n_storeArr[i]['status']">
													{{ 'status' | i18n }}
												</mat-slide-toggle>
											</td>
											<td>
												<button mat-mini-fab color="warn" (click)="updatePnS(p.store_id)"
													[disabled]="!allow_managing_quantities" title="{{ 'delete' | i18n }}">
													<mat-icon>delete</mat-icon>
												</button>
											</td>
										</tr>
									</table>
								</div>
							</mat-card-content>
						</mat-card>

					</mat-card-content>
				</mat-card>

			</div>
		</mat-tab>
		<span class="spacer"></span>

		<mat-tab *ngFor="let v of item.variants; index as idx;">
			<ng-template mat-tab-label>
				{{ 'product_variants' | i18n }}
			</ng-template>
			<mat-card>
				<mat-card-content>
					<ul class="nav nav-pills nav-fill">
						<li *ngFor="let vi of v.options" class="nav-item">
							<a class="nav-link active" href="javascript:void(0)">
								{{ vi.label[config.lang] }} :
								{{ vi.name[config.lang] }} :
								{{ vi.value }}
							</a>
						</li>
					</ul>
					<table class="table table-bordered">
						<tr>
							<td>{{ 'price' | i18n }}</td>
							<td>{{ v.price || item.price }}</td>
						</tr>
						<tr>
							<td>{{ 'sku' | i18n }}</td>
							<td>{{ v.sku }}</td>
						</tr>
						<tr>
							<td>{{ 'soft_code' | i18n }}</td>
							<td>{{ v.soft_code }}</td>
						</tr>
					</table>
					<div class="row">

						<div class="col-3" *ngFor="let pic of v.gallery_pictures">
							<img src="{{environment.mediaUrl}}{{pic}}"
								style="width: 100%; max-height: 100px; overflow: hidden;" />
						</div>

					</div>
					<div class="form-row" *ngIf="v.prod_n_storeArr">
						<table class="table table-bordered">
							<tr>
								<td>{{ 'store' | i18n }}</td>
								<td>{{ 'quantity' | i18n }}</td>
								<td>{{ 'status' | i18n }}</td>
							</tr>
							<tr *ngFor="let p of v.prod_n_storeArr; let i = index;">
								<td>{{ storesObj[p['store_id']] }}</td>
								<td>
									<mat-form-field appearance="outline" class="form-group col-md-12">
										<mat-label>{{ 'quantity' | i18n }}</mat-label>
										<input matInput [(ngModel)]="v.prod_n_storeArr[i]['quantity']" [readonly]="!allow_managing_quantities"
											placeholder="{{ 'quantity' | i18n }}" />
									</mat-form-field>
								</td>
								<td>
									<mat-slide-toggle [(ngModel)]="v.prod_n_storeArr[i]['status']" [disabled]="!allow_managing_quantities"
										[checked]="v.prod_n_storeArr[i]['status']">
										{{ 'status' | i18n }}
									</mat-slide-toggle>
								</td>
								<td>
									<button mat-mini-fab color="warn" (click)="updateVnS(p.store_id)" [disabled]="!allow_managing_quantities"
										title="{{ 'delete' | i18n }}">
										<mat-icon>delete</mat-icon>
									</button>
								</td>
							</tr>
						</table>
					</div>

					<div class="row">
						<div class="col-md-6 offset-md-3">
							<button mat-flat-button color="danger" class="btn btn-block btn-danger"
								(click)="delete_variant_product(idx)" [disabled]="!item.sku">
								{{ 'delete_variant' | i18n }}
							</button>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</mat-tab>

		<mat-tab>
			<ng-template mat-tab-label>
				<mat-icon>add_circle</mat-icon>
				{{ 'add_product_variant' | i18n }}
			</ng-template>
			<mat-card>
				<mat-card-content>
					<div class="row">
						<div *ngFor="let o of options; let i = index;" class="col-6">
							<mat-form-field appearance="outline" class="form-group col-12">
								<mat-label>{{ o.name[config.lang] }}</mat-label>
								<mat-select placeholder="{{ o.name[config.lang] }}"
									[(ngModel)]="variant_product_options[i]">
									<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
									<mat-option *ngFor="let c of o.options_list" [value]="c">
										{{c.name[config.lang]}}
										<span *ngIf="o.type == option_type.COLOR" class="int_color"
											style="background-color: {{ c.value }};">
										</span>
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'soft_code' | i18n }}</mat-label>
						<input type="number" matInput [(ngModel)]="variant_product.soft_code"
							[errorStateMatcher]='validate_variant_product.soft_code'
							placeholder="{{ 'soft_code' | i18n }}" />
						<mat-error>{{ errors.soft_code }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'price' | i18n }}</mat-label>
						<input type="number" matInput [(ngModel)]="variant_product.price"
							[errorStateMatcher]='validate_variant_product.price' placeholder="{{ 'price' | i18n }}" />
						<mat-error>{{ errors.price }}</mat-error>
					</mat-form-field>

					<mat-card>
						<mat-card-subtitle>{{'gallery_pictures' | i18n}}</mat-card-subtitle>
						<mat-card-content>

							<mat-form-field appearance="outline" class="form-group col-md-12 mat-upload">
								<input type="file" (change)="upload_multi_variant($event.target.files)" multiple />
								<input matInput hidden [(ngModel)]="variant_product.gallery_pictures"
									name="variant_product_gallery_pictures">
							</mat-form-field>

							<fieldset appearance="outline" class="form-group col-md-12 mat-multi-upload">
								<div class="col-3" *ngFor="let pic of variant_product.gallery_pictures">
									<img src="{{environment.mediaUrl}}{{pic}}" height="48" />
									<button mat-mini-fab color="warn" (click)="deletePicture_variant_product(pic)"
										title="{{ 'delete' | i18n }}">
										<mat-icon>delete</mat-icon>
									</button>
								</div>
							</fieldset>

						</mat-card-content>
					</mat-card>

					<mat-card>
						<mat-card-subtitle>{{ 'sorting_n_stores' | i18n }}</mat-card-subtitle>
						<mat-card-content>
							<div class="row" *ngIf="allow_managing_quantities">

								<mat-form-field appearance="outline" class="form-group col-md-6">
									<mat-label>{{ 'store' | i18n }}</mat-label>
									<mat-select placeholder="{{ 'store' | i18n }}" [(ngModel)]="variant_n_store['store_id']"
										[errorStateMatcher]='validate_variant_n_store["store_id"]' name="store_id">
										<mat-option *ngFor="let c of stores" [value]="c._id">
											{{ c.name[config.lang] }}
										</mat-option>
									</mat-select>
								</mat-form-field>

								<mat-form-field appearance="outline" class="form-group col-md-6">
									<mat-label>{{ 'quantity' | i18n }}</mat-label>
									<input matInput [(ngModel)]="variant_n_store['quantity']" [readonly]="!allow_managing_quantities"
										[errorStateMatcher]='validate_variant_n_store["quantity"]' name="quantity"
										placeholder="{{ 'quantity' | i18n }}" />
								</mat-form-field>

								<div appearance="outline" class="form-group col-md-6">
									<mat-label>{{ 'status' | i18n }}</mat-label>
									<mat-slide-toggle [(ngModel)]="variant_n_store['status']" [disabled]="!allow_managing_quantities"
										[checked]="variant_n_store['status']">
									</mat-slide-toggle>
									<mat-error>{{ errors.status }}</mat-error>
								</div>

								<div appearance="outline" class="form-group col-md-6">
									<button mat-raised-button color="primary" [disabled]="allow_managing_quantities && (!variant_n_store['store_id'] || !variant_n_store['quantity'] || +variant_n_store['quantity'] <= 0
											|| variant_n_store['status']===null)" (click)="add_variantnstore()">
										<mat-icon>add</mat-icon>
										{{ 'add' | i18n }}
									</button>
								</div>

							</div>
							<div class="form-row" *ngIf="variant_product.prod_n_storeArr">
								<table class="table table-bordered">
									<tr>
										<td>{{ 'store' | i18n }}</td>
										<td>{{ 'quantity' | i18n }}</td>
										<td>{{ 'status' | i18n }}</td>
									</tr>
									<tr *ngFor="let p of variant_product.prod_n_storeArr; let i = index;">
										<td>{{ storesObj[p['store_id']] }}</td>
										<td>
											<mat-form-field appearance="outline" class="form-group col-md-12">
												<mat-label>{{ 'quantity' | i18n }}</mat-label>
												<input matInput [(ngModel)]="variant_product.prod_n_storeArr[i]['quantity']"
													[readonly]="!allow_managing_quantities"
													placeholder="{{ 'quantity' | i18n }}" />
											</mat-form-field>
										</td>
										<td>
											<mat-slide-toggle [(ngModel)]="variant_product.prod_n_storeArr[i]['status']"
												[disabled]="!allow_managing_quantities" [checked]="variant_product.prod_n_storeArr[i]['status']">
												{{ 'status' | i18n }}
											</mat-slide-toggle>
										</td>
										<td>
											<button mat-mini-fab color="warn" (click)="updateVnS(p.store_id)" [disabled]="!allow_managing_quantities"
												title="{{ 'delete' | i18n }}">
												<mat-icon>delete</mat-icon>
											</button>
										</td>
									</tr>
								</table>
							</div>
						</mat-card-content>
					</mat-card>

				</mat-card-content>

				<mat-card-actions class="row">
					<div class="col-md-6 offset-md-3">
						<button mat-flat-button color="success" class="btn btn-block btn-success"
							(click)="add_variant_product()" [disabled]="!item.sku">
							{{ 'save_variant' | i18n }}
						</button>
					</div>
				</mat-card-actions>

			</mat-card>
		</mat-tab>

	</mat-tab-group>

</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
