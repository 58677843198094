import { Component, AfterViewInit } from '@angular/core';
import * as Chart from 'chart.js';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { i18n } from 'src/app/@core/libraries/i18n';

@Component({
	selector: 'app-statistic-orders',
	templateUrl: './statistic-orders.component.html',
	styleUrls: ['./statistic-orders.component.scss']
})
export class StatisticOrdersComponent implements AfterViewInit {

	title = 'angular8chartjs';
	canvas: any;
	ctx: any;
	isLoading = false;

	constructor(public http: HttpClient) { }

	ngAfterViewInit() {
		this.isLoading = true;
		this.http.get(`${environment.apiUrl}/statistic/orders`).subscribe((response: any) => {
			this.isLoading = false;
			this.canvas = document.getElementById('ordersChart');
			this.ctx = this.canvas.getContext('2d');
			const data = response.results.map((i) => i.orders_count);
			const labels = response.results.map((i) => i.date_day);
			const colors = response.results.map((i) => 'rgba(255, 206, 86, 1)');
			return new Chart(this.ctx, {
				type: 'bar',
				data: {
					labels: labels,
					datasets: [{
						label: i18n.statistic_of_orders,
						data: data,
						backgroundColor: colors,
						borderWidth: 1
					}]
				},
				options: {
					responsive: false,
					display: true
				}
			});
		});
	}

}
