import { i18n } from '../libraries/i18n';
export const Pages = [
	{
		id: '',
		title: i18n['home'],
		icon: 'home',
		home: true,
	},
	{
		id: 'product',
		title: i18n['products'],
		icon: 'tv',
		home: false,
	},
	{
		id: 'category',
		title: i18n['categories'],
		icon: 'folder',
		home: false,
	},
	{
		id: 'rank',
		title: i18n['ranks'],
		icon: 'folder',
		home: false,
	},
	{
		id: 'feature',
		title: i18n['features'],
		icon: 'stars',
		home: false,
	},
	{
		id: 'slide',
		title: i18n['slides'],
		icon: 'images',
		home: false,
	},
	{
		id: 'order',
		title: i18n['orders'],
		icon: 'shopping-cart',
		home: false,
	},
	{
		id: 'issue',
		title: i18n['issues'],
		icon: 'phone-volume',
		home: false,
	},
	/* {
		id: 'inventory',
		title: i18n['inventories'],
		icon: 'store',
		home: false,
	}, */
	{
		id: 'setting',
		title: i18n['settings'],
		icon: 'settings',
		home: false,
	},
];
