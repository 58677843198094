import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { EmployeeService } from './employee.service';
import { DetailsComponent } from './details/details.component';
import { FormComponent } from './form/form.component';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';

@Component({
	selector: 'app-employee',
	templateUrl: './employee.component.html',
	styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent extends BaseIndex {

	model = 'employee';
	displayedColumns: string[] = ['select', '_id', 'name', 'email', 'created', 'status', 'tools'];
	theDetailsComponent = DetailsComponent;
	theFormComponent = FormComponent;

	constructor(protected theService: EmployeeService,
		protected router: Router, protected dialog: MatDialog) {
		super(theService, router, dialog);
	}

}
