import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpResponse,
	HttpErrorResponse,
	HttpClient
} from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppSettings {

	public version: string;
	public apiEndpointSomeData: string;

	constructor(private http: HttpClient) { }

	load(): Promise<any> {

		const promise = this.http.get(`${environment.apiUrl}/setting`)
			.toPromise()
			.then((body) => {
				if (body['status_code'] !== 200) {
					return false;
				}
				const settings = {};
				for (const k of Object.keys(body['results'])) {
					const i = body['results'][k];
					settings[i.key] = i.value;
				}
				localStorage.setItem('app.settings', JSON.stringify(settings));
			});

		return promise;
	}

}
