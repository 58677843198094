import { Component } from '@angular/core';
import { Employee, EmployeeError } from '../employee.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { Inventory } from '../../inventory/inventory.model';
import { Department } from '../department.model';
import { i18n } from '../../../@core/libraries/i18n';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'employee';
	item: Employee = new Employee();
	errors: EmployeeError = new EmployeeError();
	validate: EmployeeError = new EmployeeError();
	inventories: Inventory[] = [];
	departments: Array<Department>;

	initialize() {
		this.loadStores();
		this.departments = [
			new Department(1, i18n.department_delivery),
			new Department(2, i18n.department_processing),
		];
	}

	loadStores() {
		this.http.get(`${environment.apiUrl}/inventory`, {
			params: new HttpParams()
				.set('filter', `status=true`)
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.inventories = response['results']['data'];
			});
	}

	getEmptyErrors() {
		return new EmployeeError();
	}

}
