import { LanguagesObject } from '../../@core/data/languages-object';
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';

export class Order implements ITable {
	_id: String;

}

export class OrderError implements IError {

}

export class OrderFilter {
	issues?: string = null;
	inventory_id?: string = null;
	supplier_id?: string = null;
	customer_no?: string = null;
	employee_no?: string = null;
	updated_quantities?: boolean = null;
	coupon?: string = null;
	status?: Number = null;
}
