import { Component } from '@angular/core';
import { Pages } from './@core/data/pages';
import { Router } from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'Jm3eia Seller';
	hasMainStyle = false;
	pages = [];
	user?: any = null;

	constructor(private router: Router) {
		let user = localStorage.getItem('auth.user');
		this.user = user = user ? JSON.parse(user) : {};
		this.get_pages();
	}

	change_language(code) {
		localStorage.setItem('lang', code);
		location.reload();
	}

	get_pages() {
		const user = this.user;
		this.pages = Object.keys(user).length ? Pages.filter((p) => {
			return p.id === '' || !user['permissions'] || user['permissions'].indexOf(p.id) > -1;
		}) : [];
		if (this.pages && user) {
			this.hasMainStyle = true;
		}
		return this.pages;
	}

	logout() {
		localStorage.removeItem('auth.user');
		localStorage.removeItem('app.settings');
		localStorage.removeItem('token');
		this.router.navigate(['login']);
	}

}
