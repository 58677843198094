import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';

export class Employee implements ITable {

	_id: String = '';
	name: String = '';
	email: String = '';
	department: String = '';
	inventory_id: String = '';
	status: Boolean = false;
}

export class EmployeeError implements IError {

	name: String = '';
	email: String = '';
	department: String = '';
	inventory_id: String = '';
	status: String = '';
}
